.donate {
  margin-top: -21px;
  background-color: #a40000;
  background-image: url('/assets/img/bg/donate.jpg');
  cursor: default;
}

.donate-thanks {
  background-image: url('/assets/img/bg/larry-lessthanthree.png');
  background-size: contain;
  background-position: 50% 50%;
  height: 400px;
}

.donate h1 {
  color: white;
  text-shadow: 0px 0px 5px black;
}

.donate p {
  text-shadow: 0px 0px 5px black;
  color: white;
}

@media (min-width: 768px) {
  .donate-form {
    margin-left: 20%;
    margin-right: 20%;
  }
}
article.newsitem {
  margin-top: 2em;
}

.newsitem-headline {
  padding-left: 45px;
}

.newsitem-content {
  padding-left: 46px;
}

.newsitem-bullet {
  color: #aaa;
  float: left;
}

@media (max-width: 768px) {
  .newsitem-bullet {
    display: none;
  }

  .newsitem-content, .newsitem-headline {
    padding-left: 0;
  }
}

.news-img-right {
  float: right;
  margin-left: 2em;
}

.news-more {
  text-align: right;
  margin: 0;
  margin-top: 18px;
  padding: 0;
  font-size: 90%;

  a:link, a:active, a:visited, a:hover {
    color: #aaa;
  }

  hr {
    margin-top: 2px;
  }
}

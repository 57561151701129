ul.sitemap {
  padding-left: 1em;

  li {
    list-style-type: none;
  }
}

@media (min-width: 768px) {
  .container-sitemap {
    padding-top: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

@media (min-width: 992px) {
  .container-sitemap {
    border-top: 1px solid #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
  }
}

.footer-breadcrumb {
  margin-top: 2em;
}
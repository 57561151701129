.devmap-entry {
  background-color: #f7f7f7;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 4px;
  max-width: 300px;
}

.devmap-entry h3 small {
  color: #333;
  font-size: 80%;
  mmargin-left: 1em;
}
.hero-section {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 200px;
  font-size: 120%;
  padding-top: 2%;
  padding-bottom: 2%;
}

@media (min-width: 768px) {
  .hero-section {
    font-size: 150%;
  }

  .hero-section h2 {
    font-size: 130%;
  }
}

.get-started-livecd {
  color: black;
  background-color: #B0BEC5;
}

.get-started-handbook {
  color: black;
  background-color: #90A4AE;
  margin-top: -1px;
}

.get-started-go {
  color: white;
  background-color: #54487a;
  margin-top: -1px;
}

.emergehdr {
  margin-top: -21px;
  background-color: #54487a;
  background-image: url('/assets/img/bg/emerge.jpg');
  cursor: default;
  min-height: 0;
  box-shadow: 0px 0px 5px #333;
  padding-top: 1em;
  padding-bottom: 1em;
}

@media (min-width: 992px) {
  .emergehdr {
    font-size: 1.95em;
  }
}

.emergehdr p {
  text-shadow: 0px 0px 5px black;
  color: white;
  margin: 0;
}

.emergehdr .buttons {
  margin-top: 3px;
}

/* about */
.abouthdr {
  background: rgba(194,188,214,1);
  background: -moz-linear-gradient(-45deg, rgba(194,188,214,1) 0%, rgba(83,72,122,1) 43%, rgba(83,72,122,1) 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(194,188,214,1)), color-stop(43%, rgba(83,72,122,1)), color-stop(100%, rgba(83,72,122,1)));
  background: -webkit-linear-gradient(-45deg, rgba(194,188,214,1) 0%, rgba(83,72,122,1) 43%, rgba(83,72,122,1) 100%);
  background: -o-linear-gradient(-45deg, rgba(194,188,214,1) 0%, rgba(83,72,122,1) 43%, rgba(83,72,122,1) 100%);
  background: -ms-linear-gradient(-45deg, rgba(194,188,214,1) 0%, rgba(83,72,122,1) 43%, rgba(83,72,122,1) 100%);
  background: linear-gradient(135deg, rgba(194,188,214,1) 0%, rgba(83,72,122,1) 43%, rgba(83,72,122,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c2bcd6', endColorstr='#53487a', GradientType=1 );

  bbackground-size: cover;
  bbackground-repeat: no-repeat;
  bbackground-image: url('/assets/img/bg/larry-about.jpg');
  min-height: 300px;
  color: white;
}

.abouthdr .right {
  text-align: right;
  margin-right: 20%;
}

.featured-panel {
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: #eaeaea;

  p:last-child {
    margin-bottom: 0;
  }
}

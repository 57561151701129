.sponsor {
  margin-bottom: .5em;
}

@media (min-width: 768px) {
  .sponsor {
    display: flex;
    align-items: center;
  }
}

.sponsorlogo {
  text-align: center;

  img {
    margin-bottom: .5em;
  }
}

.sponsortext h3 {
  margin-top: 0;
}

.text-ad {
  display: inline-block;
  height: 125px;
  width: 125px;
  line-height: 125px;
  overflow: hidden;
  border: 1px solid #ddd;
  padding: 2px;
  font-size: 90%;
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0%, #f6f6f6 47%, #ededed 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #ffffff), color-stop(47%, #f6f6f6), color-stop(100%, #ededed));
  background: -webkit-linear-gradient(top, #ffffff 0%, #f6f6f6 47%, #ededed 100%);
  background: -o-linear-gradient(top, #ffffff 0%, #f6f6f6 47%, #ededed 100%);
  background: -ms-linear-gradient(top, #ffffff 0%, #f6f6f6 47%, #ededed 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #f6f6f6 47%, #ededed 100%);

  > .text-ad-content {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2em;
  }
}
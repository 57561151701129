.huge {
  font-size: 140%;
}

.large {
  font-size: 125%;
}

@media (min-width: 768px) {
  .huge {
    font-size: 200%;
  }

  .huge h1 {
    font-size: 200%;
  }
}

::selection {
  background: #dddaec ;
}
::-moz-selection {
  background: #dddaec ;
}

.gentoo-nav a:link,
.gentoo-nav a:visited {
  color: #54487a;
}

.old-docs {
  dd {
    margin-bottom: .5em;
    margin-left: .5em;
    font-size: 90%;
  }
}

body.nav-align-h2 #content h2:first-of-type {
  margin-top: 0;
}

.caption h3 {
  margin-top: 5px;
}

.label-aspect {
  width: 4em;
  display: inline-block;
}

.gentoo-badges img {
  max-width: 6em;
  min-width: 6em;
}

.herd-desc-col {
  width: 10em;
}

.herd-maint-col {
  width: 50%;
}

@media (min-width: 768px) {
  .frontpage-table {
    width: 100%;
    table-layout: fixed;
  }

  .frontpage-table td {
    text-overflow: ellipsis;
    max-height: 1.2em;
    overflow: hidden;
    white-space: nowrap;
  }

  .frontpage-table-planet-author {
    width: 30%;
  }

  .frontpage-table-package-atom {
    width: 30%;
  }

  .frontpage-table-wiki-title {
    width: 70%;
  }
}

.get-started-icon .fa,
.contribute-icon .fa {
  padding-left: 20px;
}

@media (max-width: 768px) {
  .button-bar {
    line-height: 3em;
  }
}

.site-logo object {
  overflow: hidden;
}

.use-flag {
  width: 25%;
}

.use-desc {
  width: 75%;
}

.external-link {
  color: #979797;
}

.stick-top {
  margin-top: 0;
}

.stick-bottom {
  margin-bottom: 0;
}

.logo-table {
  img {
    max-height: 100px;
  }

  td.img {
    text-align: center;
  }
}


.download-size {
  margin-left: 1em;
}

.download-tag {
  margin-left: 1em;
}

.stick-top {
  margin-top: 0;
}

@media (min-width: 768px) {
  .other-arches .tab-pane {
    margin-left: 1em;
  }
}
